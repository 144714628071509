.token{
    &--span{
        font-size: .75rem;
    }
    &--balance{
        font-size: 1rem;
        font-weight: 600;
        color: #111A24;
    }
    &--equivalent{
        font-size: .75rem;
        font-weight: 500;
        color: #888C91;
    }
    &__payment{
        padding-top: 20px;
        margin-top: 20px;
        margin-bottom: 50px;
        &--total{
            font-size: 1.2rem;
            font-weight: 500;
            color: #111A24;
        }
        &--token{
            font-size: 1.2rem;
            font-weight: 600;
            color: #111A24;
            letter-spacing: -.36px;
        }
    }
}
.success{
    &__card{
        padding: 48px 32px;
        border: 1px solid #E8EBED;
        border-radius: 16px;
        &--title{
            margin-top: 32px;
            font-weight: 600;
            font-size: 1.4rem;
            letter-spacing: -.48px;
            color: #111A24;
            margin-bottom: 24px;
        }
        &--description{
            font-weight: 400;
            font-size: .875rem;
            color: #394049;
            margin-top: 24px;
        }
    }
}