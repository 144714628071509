.subscription{
    &__header{
        &--1{
            font-size: 1.375rem;
            font-weight: 500;
            color: #888C91;
            letter-spacing: -.51px;
            margin-right: .5rem;
        }
        &--2{
            font-size: 1.375rem;
            font-weight: 500;
            margin-right: .5rem;
            color: #394049;
            letter-spacing: -.51px;
        }
        &--dropdown{
            background-color: transparent;
            border: 1px solid #2C4A8B;
            margin-left: 15px;
            font-weight: 500;
            color: #2C4A8B;
        }
    }
    &__profile{
        margin-top: 30px;
        margin-bottom: 50.5px;
        &--title{
            font-size: 1rem;
            font-weight: 500;
            color: #fff;
            background-color: #2C4A8B;
            height: 32px;
            width: 32px;
            text-align: center;
            border-radius: 50px;
            margin-right: 12.5px;
            justify-content: center;
        }
        &--name{
            font-size: .875rem;
            font-weight: 500;
            color: #394049;
            letter-spacing: -.14px;
        }
        &--email{
            font-size: .75rem;
            font-weight: 500;
            color: #60666D;
        }
    }
    &__plansDetails{
        margin-bottom: 40px;
        &--text{
            font-size: .875rem;
            font-weight: 500;
            color: #394049;
        }
        &--price{
            font-size: 1.7rem;
            font-weight: 700;
            color: #111A24;
        }
        &--period{
            font-size: .875rem;
            font-weight: 500;
            color: #60666D;
        }
        &--button{
            font-size: 16px;
            font-weight: 500;
            color: #2C4A8B;
            border: .5px solid #2C4A8B;
            padding: 10px 20px;
            border-radius: 5px;
            margin-top: 20px;
            cursor: pointer;
            width: 100%;
            height: 55px;
            box-sizing: border-box;
            background-color: transparent;
            font-weight: 500;
        }
        &--payment{
            background-color: #2C4A8B;
            outline: none;
            border: 1px solid #2C4A8B;
            border-radius: 5px;
            font-weight: 500;
            font-size: 16px;
            color: #fff;
            padding: 10px 20px;
            cursor: pointer;
            width: 100%;
            height: 55px;
            box-sizing: border-box;
        }
    }
    &__card{
        background: #F5F5F5 !important;
        border: 1px solid #E8EBED !important;
        &--header{
            font-size: 18px;
            font-weight: 600;
            color: #111A24;
            letter-spacing: -.18px;
        }
        &--items{
            font-size: 16px;
            color: #111A24;
            font-weight: 400;
        }
        &--amount{
            font-size: 16px;
            font-weight: 500;
            color: #111A24;
        }
        &--content{
            margin-top: 30px;
            margin-bottom: 30px;
        }
        &--footer{
            font-size: 16px;
            font-weight: 400;
            color: #111A24;
        }
        &--equivalent{
            font-size: .75rem;
            font-weight: 500;
            color: #60666D;
        }
        &--total{
            font-size: 18px;
            font-weight: 600;
        }
    }
    &__addExtras{
        &--section{
            margin-top: 36px;
        }
       &--title{
        color: #111A24;
        font-size: 25px;
        font-weight: 600;
       } 
       &--description{
         color: #394049;
         font-size: .875rem;
         font-weight: 400;
         letter-spacing: -.14px;
       }
       &--subtitle{
        font-size: 20px;
        font-weight: 600;
        color: #111A24;
        letter-spacing: -.40px;
       }
       &--selectedCard{
        background-color: #F2FAFD !important;
        border: 1px solid #67CFF3 !important;
       }
       &--card{
        padding: 20px 10px;
        border: 1px solid #B0B3B6;
        margin: 12px;
        border-radius: 5px;
            &-input{
                accent-color: #2C4A8B;
                transform: scale(1.2) !important;
                margin-right: .5rem;
            }
            &-qty{
                font-size: 16px;
                font-weight: 400;
                color: #60666D;
                margin-right: .5rem;
                letter-spacing: -.32px;
            }
            &-amount{
                font-size: 16px;
                font-weight: 600;
                color: #111A24;
                letter-spacing: -.32px;
            }
       }
    }
}
@media screen and (max-width: 320px) {
    .subscription{
        &__header{
            &--1{
                font-size: 1.1rem;
            }
            &--2{
                font-family: 1.1rem;
            }
        }
    }
}