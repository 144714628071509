.customModal{
    &--title{
        font-size: 22px;
        font-weight: 600;
        color: #111A24;
        letter-spacing: -.44px;
        margin-top: 5px;
    }
    &--description{
        font-size: 14px;
        font-weight: 400;
        color: #111A24;
        letter-spacing: -.32px;
        color: #60666D;
        margin-top: 24px;
    }
    &--close{
        height: 48px;
        width: 48px;
        background-color: #F7F7F7;
        border-radius: 9px;
        border: 1px solid #F7F7F7;
        margin-top: -50px;
    }
    &--paymentButton{
        height: 45px;
        width: 100%;
        background-color: #2C4A8B;
        border-radius: 7px;
        border: 1px solid #2C4A8B;
        color: #fff;
        font-weight: 500;
        font-size: .875rem;
    }
}